import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
/* This example requires Tailwind CSS v2.0+ */
export default function LogoCloud() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
            Trusted by businesses in various sectors across the world
          </p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <StaticImage
                className="max-h-12"
                src="../images/logos/Leuphana.svg"
                placeholder="blurred"
                alt="Leuphana logo"
                height={48}
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <StaticImage
                className="max-h-12"
                src="../images/logos/Bespoke_Arcades_logo.jpg"
                placeholder="blurred"
                alt="Bespoke Arcades logo"
                height={48}
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <StaticImage
                className="max-h-12"
                src="../images/logos/conversation-piece-logo.png"
                placeholder="blurred"
                alt="Conversation Piece logo"
                height={48}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  