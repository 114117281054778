import * as React from "react"
import Hero from "../components/hero"
import MainHome from "../components/main-home"
import Footer from "../components/footer"
import ThreeLatestBlogs from "../components/three-latest-blogs"
import ContactUsCta from "../components/contact-us-cta"
import LogoCloud from "../components/logo-cloud"
// markup
const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <Hero />
      <MainHome />
      <LogoCloud />
      {/* <ThreeLatestBlogs /> */}
      <ContactUsCta />
      <Footer />
    </main>
  )
}

export default IndexPage
